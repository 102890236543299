export const environment = {
  production: true,
  envName: 'prod',
  autoClub: 'W2C',
  addressify: {
    baseUrl: 'https://api.addressify.com.au',
    apiKey: '0E4644FE-85E4-4685-930F-DBF5F8629DFA',
  },
  verisk: {
    username: 'BB3TokioMarineW2CAusTravelProd',
    vrrInternal:
      'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiVG9raW8gTWFyaW5lIFdvcmxkIDIgQ292ZXIgQXVzIFRyYXZlbCBCQjMiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJCbGFja0JveEN1c3RvbWVyIiwiY2xpZW50SWQiOiIxMCIsImlzcyI6IkJCLk1haW50ZW5hbmNlVG9vbC5UZXN0IiwiYXVkIjoiVlJSLkJCMy5Qcm9kIn0.ENpiqTT9b8wdBXmkchJvt0Qti6s8xngaRkjVfBM_ArQ',
  },
  baseAPIUrl: 'https://apim-aue-ltx-prod-api-01.azure-api.net/v1/quote/api/',
  DMSAPI: {
    baseUrl: 'https://apim-aue-ltx-prod.azure-api.net/v1/',
    apiKey: '621809DEBVPN1198YMNEQU4W1XGTG',
    OcpApimSubscriptionKey: '1fc60dfc63504bbaaf5bdfdd642f37ad',
  },
  PASUrl: 'https://pas.tmaainsurance.com',
};
