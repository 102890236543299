import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { LocalCacheService } from '../../services/localCache.service';
import { HeaderComponent } from '../../components/header/header.component';
import { FooterComponent } from '../../components/footer/footer.component';
import moment from 'moment';
import { Router } from '@angular/router';
import { Utility } from '../../utils/utils';
import { GlobalService } from '../../services/global.service';
import { environment } from '../../../environments/environment';
import { GAService } from '../../services/ga.service';

@Component({
  selector: 'payment-confirmation',
  standalone: true,
  imports: [CommonModule, HeaderComponent, FooterComponent],
  providers: [provideNativeDateAdapter()],
  templateUrl: './payment-confirmation.component.html',
  styleUrl: './payment-confirmation.component.scss',
})
export class PaymentConfirmationComponent implements OnInit, AfterViewInit {
  isPaymentLoading: boolean = false;
  quoteDetail: any;
  quoteEnquiry: any;
  travelerDetails: any;
  insuredTravelerDetails: any;
  policyNumber: any;
  selectedCover: any;
  goAdminLink: string = '';
  agentGuid: string = '';

  constructor(
    private localCacheService: LocalCacheService,
    private router: Router,
    private globalService: GlobalService,
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    if (!this.globalService.checkAndValidatePage(5, false)) {
      this.router.navigate(['/error']);
    }
    this.quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    if (!this.quoteDetail) {
      this.router.navigate(['/']);
      return;
    }
    this.quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    this.selectedCover =
      this.localCacheService.getSessionStorage('selectedCoverDetail') || {};
    this.travelerDetails =
      this.localCacheService.getSessionStorage('travelerDetails');
    const paymentInfo = this.localCacheService.getSessionStorage('paymentInfo');
    if (paymentInfo) {
      const paymentInfoObj = JSON.parse(paymentInfo);
      this.policyNumber = paymentInfoObj.policyNumber;
    } else {
      this.policyNumber = null;
    }
    this.getInsuredTravelerDetails();
    this.agentGuid = this.localCacheService.getSessionStorage('agentGuid');
    if (this.agentGuid) {
      const paymentInfoObj = JSON.parse(paymentInfo);
      this.goAdminLink =
        environment.PASUrl +
        '/policy-detail?policyguid=' +
        paymentInfoObj.policyGuid;
    }
    this.gaService.sendPuerchaseEvent();
  }

  ngAfterViewInit(): void {
    this.localCacheService.clearAllSession();
  }

  getInsuredTravelerDetails() {
    const { ages, travelers } = this.quoteDetail;

    const insuredTravelerDetails = travelers.map(
      (traveler: any, index: any) => ({
        name: `${traveler.firstName} ${traveler.lastName}`,
        age: Utility.ageFromDDMMYYYFormat(traveler.dateOfBirth),
      }),
    );

    this.insuredTravelerDetails = insuredTravelerDetails;
  }

  get travelDays() {
    const depDate = moment(this.quoteEnquiry?.return);
    const rtnDate = moment(this.quoteEnquiry?.depart);
    const days = depDate.diff(rtnDate, 'days');
    return days;
  }

  get countryNames(): string {
    return this.quoteEnquiry?.travelDestination
      .map((country: any) => country.countryName)
      .join(', ');
  }

  get formattedInvoiceDate(): string {
    const parsedDate = moment(this.quoteDetail?.pricingDate, 'DD/MM/YYYY');
    return parsedDate.isValid()
      ? parsedDate.format('D MMMM YYYY')
      : 'Invalid date';
  }
}
