import { Injectable } from '@angular/core';
import { LocalCacheService } from './localCache.service';
import { Title } from '@angular/platform-browser';
import { GlobalService } from './global.service';
import { environment } from '../../environments/environment';
declare global {
  interface Window {
    dataLayer: any[];
    gtag: any;
  }
}
@Injectable({
  providedIn: 'root',
})
export class GAService {
  constructor(
    private localCacheService: LocalCacheService,
    private titleService: Title,
    private globalService: GlobalService,
  ) {}

  pageLoad() {
    this.safeExecute(() => {
      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      const pageInfo = {
        event: 'virtual_page_view',
        page_location: window.location.href,
        page_referrer: document.referrer,
        page_title: this.titleService.getTitle(),
        visitor_type: quoteDetail?.isClubMember ? 'member' : 'guest',
        member_id: quoteDetail?.membershipNumber ?? '',
      };
      this.sendGAEvent(pageInfo);
    });
  }

  sendLeadSubmissionEvent() {
    this.safeExecute(() => {
      const quoteEnquiry =
        this.localCacheService.getSessionStorage('quoteEnquiry');
      const eventDetails = {
        event: 'lead_submission',
        ecommerce: quoteEnquiry,
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendConfirmPayEvent() {}

  sendEmailClickEvent() {}

  sendEmailQuoteEvent() {}

  sendFileDownloadEvent() {}

  sendFinaliseQuoteEvent() {}

  //sendInternalClickEvent() {}

  //sendOtherFormEvent() {}

  //sendPolicyEvent() {}

  sendPuerchaseEvent() {
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'purchase_complete',
        ecommerce: quoteDetail,
      };
      this.sendGAEvent(eventDetails);
    });
  }

  //sendQuoteStep1Event() {}

  //sendQuoteStep2Event() {}

  //sendQuoteStep3Event() {}

  //sendQuoteStep4Event() {}

  //sendScrollEvent() {}

  //sendSessionStartEvent() {}

  sendStep2Event() {
    // step 1 page load
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const basicPrice = this.localCacheService.getSessionStorage('basicPrice');
    const topPrice = this.localCacheService.getSessionStorage('topPrice');
    const essentialPrice =
      this.localCacheService.getSessionStorage('essentialPrice');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'Step 2',
        ecommerce: {
          quoteDetail: quoteDetail,
          items: [
            { basicPrice: basicPrice },
            { topPrice: topPrice },
            { essentialPrice: essentialPrice },
          ],
        },
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendStep3Event() {
    //step 2 page load
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'Step 3',
        ecommerce: quoteDetail,
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendStep4Event() {
    //step 3 page load
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'Step 4',
        ecommerce: quoteDetail,
      };
      this.sendGAEvent(eventDetails);
    });
  }

  sendStep5Event() {
    //step 4 page load
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    this.safeExecute(() => {
      const eventDetails = {
        event: 'Step 5',
        ecommerce: quoteDetail,
      };
      this.sendGAEvent(eventDetails);
    });
  }

  //sendTelClickEvent() {}

  //sendViewSearchResultsEvent() {}

  sendAff_BranchEvent() {
    this.safeExecute(() => {
      const aff_branchObj = this.globalService.getUrlParamsToJson();
      aff_branchObj.club = environment.autoClub;
      const eventDetails = {
        event: 'Affiliate_Branch',
        ecommerce: aff_branchObj,
      };
      this.sendGAEvent(eventDetails);
    });
  }

  getDestinationsString(destinations: any) {
    let destinationString = '';
    destinations.forEach((destination: any, index: number) => {
      destinationString += destination.countryName;
      if (index < destinations.length - 1) {
        destinationString += ', ';
      }
    });
    return destinationString;
  }

  sendGAEvent(eventDetails: any) {
    window.gtag('event', eventDetails.event, eventDetails.ecommerce);
    console.log('GA Event: window.dataLayer new', window.dataLayer);
  }

  safeExecute(fn: any) {
    try {
      fn();
    } catch (error) {
      console.error('An error occurred in the Google analytics code:', error);
    }
  }
}
